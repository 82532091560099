import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hMDuration'
})
export class HMDurationPipe implements PipeTransform {

  transform(numberOfMinutes: number | null): string {
    if (numberOfMinutes == null) {
      return '-';
    }
    if (numberOfMinutes >= 60) {
      return `${Math.floor(numberOfMinutes / 60)}h ${Math.floor(numberOfMinutes % 60)}m`;
    } else {
      return `${Math.floor(numberOfMinutes % 60)}m`;
    }
  }
}
