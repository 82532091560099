import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoutModalComponent} from './components/logout-modal/logout-modal.component';
import {PrimengModule} from '@global/primeng.module';
import {InactivityComponent} from './components/inactivity/inactivity.component';
import {NotificationWidgetComponent} from './components/notification-widget/notification-widget.component';
import {WidgetContainerComponent} from './components/widget-container/widget-container.component';
import {WidgetErrorComponent} from './components/widget-error/widget-error.component';
import {SidebarComponent} from './components/sidebar/sidebar.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChargerStatusComponent} from './components/charger-status/charger-status.component'
import {HighchartsChartModule} from "highcharts-angular";
import {SuccessNotificationComponent} from './components/sidebar/success-notification/success-notification.component';
import {QuickLinksComponent} from './components/quick-links/quick-links.component';
import {FeatureControlDirective} from './directives/feature-control/feature-control.directive';
import {PieChartComponent} from '@core-shared/components/pie-chart/pie-chart.component';

import {PageContentContainerComponent} from './components/page-content-container/page-content-container.component';
import {
  CenteredWidgetContainerComponent
} from './components/centered-widget-container/centered-widget-container.component';
import {AnalyticsChartWidgetComponent} from './components/analytics-chart-widget/analytics-chart-widget.component';
import {ToastComponent} from './components/toast/toast.component';
import {ConfigHeaderComponent} from '@core-shared/components/config-builders/config-header/config-header.component';
import {ToggleButtonModule} from "primeng/togglebutton";
import {
  ConfigInputHeaderComponent
} from './components/config-builders/config-input-header/config-input-header.component';
import {
  ConfigSubsectionHeaderComponent
} from './components/config-builders/config-subsection-header/config-subsection-header.component';
import {InputErrorComponent} from '@core-shared/components/form/form-input/input-error/input-error.component';
import {ButtonBarComponent} from './components/config-builders/button-bar/button-bar.component';
import {WidgetInfoComponent} from './components/widget-info/widget-info.component';
import {FormButtonBarComponent} from '@core-shared/components/form/form-button-bar/form-button-bar.component';
import {CancelModalComponent} from "@core-shared/components/config-builders/cancel-modal/cancel-modal.component";
import {FormErrorComponent} from './components/config-builders/form-error/form-error.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {TableTemplatesComponent} from './templates/table-templates/table-templates.component';
import { ModalTemplatesComponent } from './templates/modal-templates/modal-templates.component';
import { StepComponent } from './components/step/step.component';
import {DialogService} from "primeng/dynamicdialog";
import { MessagesComponent } from './components/messages/messages.component';
import {MessagesModule} from "primeng/messages";
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { FormControlPipe } from './pipes/form-control.pipe';
import {InputNumberModule} from "primeng/inputnumber";
import { FormInputComponent } from './components/form/form-input/form-input.component';
import { FormTextInputComponent } from './components/form/form-input/form-text-input/form-text-input.component';
import { FormDropdownInputComponent } from './components/form/form-input/form-dropdown-input/form-dropdown-input.component';
import { FormToggleButtonComponent } from './components/form/form-input/form-toggle-button/form-toggle-button.component';
import { FormRadioComponent } from './components/form/form-input/form-radio/form-radio.component';
import {RadioButtonModule} from "primeng/radiobutton";
import { FormCalendarComponent } from './components/form/form-input/form-calendar/form-calendar.component';
import { EnergyUsagePipe } from './pipes/metrics/energy-usage.pipe';

@NgModule({
  declarations: [
    LogoutModalComponent,
    InactivityComponent,
    NotificationWidgetComponent,
    WidgetContainerComponent,
    WidgetErrorComponent,
    SidebarComponent,
    ChargerStatusComponent,
    SuccessNotificationComponent,
    QuickLinksComponent,
    FeatureControlDirective,
    PieChartComponent,
    PageContentContainerComponent,
    CenteredWidgetContainerComponent,
    AnalyticsChartWidgetComponent,
    ToastComponent,
    ButtonBarComponent,
    ToastComponent,
    ConfigHeaderComponent,
    ConfigInputHeaderComponent,
    ConfigSubsectionHeaderComponent,
    InputErrorComponent,
    WidgetInfoComponent,
    FormButtonBarComponent,
    CancelModalComponent,
    FormErrorComponent,
    ConfirmDialogComponent,
    TableTemplatesComponent,
    ModalTemplatesComponent,
    StepComponent,
    MessagesComponent,
    LineChartComponent,
    FormControlPipe,
    FormInputComponent,
    FormTextInputComponent,
    FormDropdownInputComponent,
    FormToggleButtonComponent,
    FormRadioComponent,
    FormCalendarComponent,
    EnergyUsagePipe
  ],
    imports: [
        CommonModule,
        PrimengModule,
        FormsModule,
        ReactiveFormsModule,
        HighchartsChartModule,
        ToggleButtonModule,
        MessagesModule,
        InputNumberModule,
        RadioButtonModule
    ],
    exports: [
        LogoutModalComponent,
        InactivityComponent,
        PrimengModule,
        FormsModule,
        ReactiveFormsModule,
        WidgetContainerComponent,
        NotificationWidgetComponent,
        SidebarComponent,
        ChargerStatusComponent,
        QuickLinksComponent,
        FeatureControlDirective,
        PieChartComponent,
        PageContentContainerComponent,
        CenteredWidgetContainerComponent,
        AnalyticsChartWidgetComponent,
        ToastComponent,
        ButtonBarComponent,
        ConfigHeaderComponent,
        ConfigInputHeaderComponent,
        ConfigSubsectionHeaderComponent,
        InputErrorComponent,
        FormButtonBarComponent,
        CancelModalComponent,
        FormErrorComponent,
        ConfirmDialogComponent,
        TableTemplatesComponent,
        StepComponent,
        MessagesComponent,
        LineChartComponent,
        FormControlPipe,
        FormTextInputComponent,
        FormDropdownInputComponent,
        FormInputComponent,
        FormToggleButtonComponent,
        FormRadioComponent,
        FormCalendarComponent,
        WidgetErrorComponent,
        EnergyUsagePipe
    ],
  providers: [DialogService]
})
export class CoreSharedModule {
}
