<ng-template #buttons>
    <div class="h-2rem max-h-2rem flex justify-content-center custom-button-width">
        <p-selectButton #selectButton styleClass="h-2rem max-h-2rem" [options]="options" [(ngModel)]="incomingChartType"
            (onChange)="updateChartDataSource()">
            <ng-template let-item pTemplate>
                <span
                    class="flex align-items-start gap-0 p-0 text-sm font-semibold h-1rem max-h-1rem">{{item.label}}</span>
            </ng-template>
        </p-selectButton>
    </div>
</ng-template>
<atom-widget-container 
    [widgetState]="widgetState"
    [infoMessage]="infoMessage"
    [headerText]="incomingData?.useBarChart ? headerText : incomingHeaderText"
    [errorHeaderText]="incomingData?.useBarChart ? errorHeaderText : incomingHeaderText"
    [rightHeaderTemplate]="incomingData?.useBarChart ? buttons : undefined" [contentStyleClass]="'gap-4 min-h-0'"
    [disableFooter]="widgetState === 1">
    <div *ngIf="!incomingData?.useBarChart"
        class="flex justify-content-center align-items-center align-self-stretch gap-4">
        <div class="relative justify-content-center align-items-center z-3 donut-container">
            <div class="absolute donut-container border-container">
                <svg class="svg-border" width="189" height="189" viewBox="0 0 204 203" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="101.5" cy="101.5" r="58" stroke-width="15" />
                    <circle cx="101.5" cy="101.5" r="91" stroke-width="15" />
                </svg>
            </div>
            <highcharts-chart #chart [Highcharts]="highcharts" [(update)]="chartUpdate" [options]="chartOptions"
                style="z-index: 3; display: flex; position: absolute; width: 11.79406rem; height: 11.79406rem;">

            </highcharts-chart>
            <div class="flex absolute justify-content-center align-items-center flex-shrink-0 gap-1 inner-container">
                <div
                    class="flex flex-column justify-content-center align-items-center flex-shrink-0 gap-1 inner-text-container">
                    <a
                        class="flex justify-content-center align-self-stretch text-center text-lg font-bold inner-container-top-text">{{donutTopMiddleText}}</a>
                    <a
                        class="flex justify-content-center align-self-stretch text-center text-sm font-normal inner-container-bottom-text">
                        {{ donutMiddleText}}
                    </a>
                </div>
            </div>
        </div>
        <div class="flex flex-column align-items-start gap-2 legend-size">
            <ng-container *ngFor="let stat of incomingData; let i = index">
                <div *ngIf="stat.y > 0" class="flex align-items-center gap-2 align-self-stretch legend-item-container">
                    <svg class="legend-color-key" xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                        viewBox="0 0 13 13" fill="none">
                        <circle cx="6.5" cy="6.5" r="6" [attr.fill]="stat.color" />
                    </svg>
                    <span class="text-xs w-12rem font-normal legend-text-key legend-text text-overflow-ellipsis white-space-nowrap overflow-hidden">
                        ({{stat.y}}{{modifier}}) {{stat.name}}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="incomingData?.useBarChart" class="relative flex justify-content-center top-padding">
        <highcharts-chart [id]="'bar-chart-' + barChartCategory" #chart [Highcharts]="highcharts"
            [(update)]="chartUpdate" class="custom-width block z-3 flex justify-content-center w-full"
            [options]="chartOptions">
        </highcharts-chart>
    </div>
</atom-widget-container>
